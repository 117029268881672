import React, { useState } from "react";
import { Icon, SystemProps } from "@storyofams/react-ui";

import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { Button } from "../../../components";
import {
  Bag,
  CartSmall,
  Checkmark,
  Plus,
} from "../../../components/common/Icons";
import { useShopify } from "../../../context";
import { ProductByHandleQuery } from "../../../lib/shopify/sdk";

interface ProductSliderProps {
  product?: ProductByHandleQuery["productByHandle"] | any;
  inStockBundle?: boolean;
  isBundle?: boolean;
}

export const AddToCartButton = React.memo(
  ({
    product,
    isBundle = false,
    inStockBundle,
    ...props
  }: ProductSliderProps & SystemProps) => {
    const { addItemToCart, updatingCart, cart } = useShopify();
    const [adding, setAdding] = useState(null);
    const posthog = usePostHog();
    const router = useRouter();
    const inStock = isBundle
      ? !inStockBundle
      : product?.variants_inventory_count > 0 || product?.availableForSale;
    const firstAvailableVariant = isBundle
      ? product.variants.nodes[0].id
      : product?.variants?.edges?.find(
          (item) =>
            item?.node.availableForSale && item?.node.priceV2.amount !== "0.0"
        )?.node?.id;
    const isInTheCart = !!cart?.lines?.edges?.find(
      ({ node }) => node.merchandise?.product?.handle === product?.handle
    );
    return (
      <Button
        variant="secondary"
        py="8px !important"
        px="18px !important"
        width={80}
        minHeight={38}
        maxHeight={38}
        {...props}
        hideArrow
        isLoading={updatingCart && adding === product?.id}
        disabled={isInTheCart || !inStock}
        onClick={() => {
          posthog.capture("Clicked Add to Cart", {
            source: "Product Card",
            product: product.title,
            page: router.pathname,
            properties: {
              $process_person_profile: false,
            },
          });
          setAdding(product?.id);
          addItemToCart(
            [
              {
                quantity: 1,
                merchandiseId: firstAvailableVariant,
              },
            ],
            () => setAdding(null)
          );
        }}
      >
        {isInTheCart ? (
          <Icon icon={Checkmark} color="black" fontSize={2.5} />
        ) : (
          <Icon lineHeight={3.5} fontSize={3.5} icon={Plus} />
        )}
        <Icon fontSize={2.5} icon={CartSmall} ml={0.75} />
      </Button>
    );
  }
);
